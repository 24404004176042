import IMask from "imask";
import { onDOMLoad } from "./utils";

// modal form
onDOMLoad(() => {
  let sendMail = async function sendMail(form) {
    let data = new FormData(form);
      return fetch('/mail.php', {
          method: "POST",
          body: data
      });
  };

  function sendForm2(event?) {
    event.preventDefault();
    let form = document.querySelector(".callbackForm__form");
    if (form) {
      form.addEventListener("submit", function (event) {
        event.preventDefault();
        // @ts-ignore
        // scrollLock.disablePageScroll();
        this.parentNode.parentNode.nextElementSibling.classList.add(
          "success__active"
        );
        sendMail(event.target);
      });
    }
  }
  sendForm2(event);

  function successMessageClose() {
    let popup = document.querySelector(".callbackPopup");
    let bg = document.querySelector(".callbackPopup__bg");
    let close = document.querySelector(".success__close");
    let btnClose = document.querySelector(".success__button");
    let container = document.querySelector(".success");
    close.addEventListener("click", function () {
      container.classList.remove("success__active");
    //   scrollLock.enablePageScroll();
    //   scrollLock.enablePageScroll();
      popup.classList.remove("callbackPopup__active");
      bg.classList.remove("callbackPopup__bg__active");
    });
    btnClose.addEventListener("click", function () {
        container.classList.remove("success__active");
      //   scrollLock.enablePageScroll();
      //   scrollLock.enablePageScroll();
        popup.classList.remove("callbackPopup__active");
        bg.classList.remove("callbackPopup__bg__active");
      });
    setTimeout(function () {
      container.classList.remove("success__active");
    //   scrollLock.enablePageScroll();
    //   scrollLock.enablePageScroll();
      popup.classList.remove("callbackPopup__active");
      bg.classList.remove("callbackPopup__bg__active");
    }, 20000);
  }
  successMessageClose();
});
